<template>
  <div class="oe">
    <HeaderView />
    <div class='container'>
      <h1 class="original-title">Original Experimental Results Display</h1>
      <div class="original-content">
        <tree-charts @node-click="handleNodeClick" :representation-name="representationParams"></tree-charts>
        <div class='content'>
          <div class='content-top'>
            <div class="description" v-if="currentData.description">
              {{ currentData.description }}
            </div>
            <img :src="currentData.picture" v-if="currentData.picture" alt="暂无数据" />
          </div>
          <div class='tables'>
              <h2>Regression Task Dataset</h2>
              <vxe-table
                  empty-text="No Data"
                  round
                  header-cell-class-name="table-header"
                  cell-class-name="table-cell"
                  class="file-scrollbar"
                  align="center"
                  max-height="30%"
                  :scroll-y="{ enabled: true }"
                  :scroll-x="{ enabled: true }"
                  :data="currentData?.regressionTask?.data">
                <vxe-column
                    v-for="item in currentData?.regressionTask?.column"
                    :key="item.value"
                    :field="item.value"
                    :title="item.label"
                    :formatter="formatterColumn"
                    :min-width="item.width">
                </vxe-column>
              </vxe-table>
              <h2>Classification Task Dataset</h2>
              <vxe-table
                  empty-text="No Data"
                  round
                  header-cell-class-name="table-header"
                  cell-class-name="table-cell"
                  class="file-scrollbar"
                  align="center"
                  height="35%"
                  :scroll-y="{ enabled: true }"
                  :scroll-x="{ enabled: true }"
                  :data="currentData?.classificationTask?.data">
                <vxe-column
                    v-for="item in currentData?.classificationTask?.column"
                    :key="item.value"
                    :field="item.value"
                    :title="item.label"
                    :min-width="item.width">
                </vxe-column>
              </vxe-table>
          </div>
        </div>
      </div>
    </div>
    <footer-view />
  </div>

</template>

<script>
import TreeCharts from "@/views/benchmark/TreeCharts.vue";
import axios from "axios";
import * as XLSX from "xlsx";
import HeaderView from "@/layout/Header.vue";
import FooterView from "@/layout/Footer.vue";
export default {
  components: {
    FooterView,
    HeaderView,
    TreeCharts,
  },
  beforeRouteEnter(to, from, next) {
    if (to.query) {
      next((vm) => {
        vm.representationParams = to.query
        vm.handleNodeClick(to.query)
      })
    }
  },
  data() {
    return {
      currentNode: 'SELFIES', // 默认显示的节点
      currentData: {},
      representationParams: ''
    }
  },
  methods: {
    async handleNodeClick(node) {
      if (node.children) return
      this.currentNode = node.name
      this.currentData = {
        ...node,
        regressionTask: await this.getClassificationTask(node.regressionUrl),
        classificationTask: await this.getClassificationTask(node.classificationUrl)
      }
    },
    async getClassificationTask(url) {
      if (url) {
        /// /biobank/reportTemplate/5e7ac090233167dcfccd9031/group/dashboard/AMOR/benchmark/OE/learned/processed_Trimnet-分类.xlsx
        const res = await axios.get(url, {responseType: 'arraybuffer'})
        const data = new Uint8Array(res.data) // 将 ArrayBuffer 转换为 Uint8Array
        const workbook = XLSX.read(data, {type: 'array'}) // 读取工作簿
        const sheetName = workbook.SheetNames[0] // 获取第一个工作表的名称
        const sheet = workbook.Sheets[sheetName] // 获取工作表
        const column = XLSX.utils.sheet_to_json(sheet, {header: 1})[0].map(item => {
          return {value: item, label: item, width: item === 'Model-Fingerprint' ? 380 : 250}
        })
        let xlsxData = XLSX.utils.sheet_to_json(sheet)
        xlsxData.forEach((item, index) => {
          if (!item.Datasets && index >= 1) {
            item.Datasets = xlsxData[index-1].Datasets
          }
          // return item
        })
        return {
          column,
          data: xlsxData
        }
      } else return {
        data: [],
        column: []
      }
    },
    formatterColumn({cellValue}) {
      // console.log(cellValue.indexOf('±'))
      return cellValue
    }
  }
}

</script>

<style scoped lang="less">
@import "@/css/vxetable-change.less";
.oe {
  height: 100%;
  .container {
    padding: 3rem 3rem;
    height: 100%;
    box-sizing: border-box;
    /*  overflow-y: scroll;*/
  }
}

.original-title {
  font-size: 0.82rem;
  line-height: 1rem;
  margin-bottom: 0.82rem;
  background: #7DD1C180;
  width: 16.5rem;
  height: 2.05rem;
  padding: 0.41rem 0;
  box-sizing: border-box;
  text-align: center;
  font-weight: 700;
  color: #3F4760;
  margin-left: 0.727rem;
  border-radius: 0.18rem;
}
.original-content {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.container::-webkit-scrollbar{
  width: 6px;
  height: 6px;
}
.container::-webkit-scrollbar-track{
  background: rgb(239, 239, 239);
  border-radius: 2px;
}
.container::-webkit-scrollbar-thumb{
  background: #bfbfbf;
  border-radius: 10px;
}
.container::-webkit-scrollbar-thumb:hover{
  background: #333;
}
.container::-webkit-scrollbar-corner{
  background: #179a16;
}
.content{
  width: 65%;
  height: 100%;
  padding-left: 3.91rem;
  .content-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    .description {
      padding: 0.23rem 0;
      font-size: 0.82rem;
      line-height: 1rem;
      flex: 1;
      color: #706592;
      font-weight: 700;
      text-align: left;

    }
    img {
      height: 10rem;
      max-width: 100%;
      object-fit: contain;
    }
  }
}
.tables{
  width: 100%;
  height: 70%;
  box-sizing: border-box;
  //.table1,
  //.table2 {
  //  height: 50%;
  //}
  h2 {
    font-size: 1rem;
    margin-top: 1.45rem;
    margin-bottom: 0.91rem;
    color: #3F4760;
  }
}
@media (min-width: 1440px) {
  .oe .container{
    max-width: 68rem;
    margin: 0 auto;
    box-sizing: border-box;
  }
}
</style>
