<script>
import HeaderView from "@/layout/Header.vue";
import HomeView from "@/views/home/HomeView.vue";
import FooterView from "@/layout/Footer.vue";
import OriginalExperimentalResults from "@/views/benchmark/OriginalExperimentalResults.vue";

export default {
  name: "LayoutView",
  components: {OriginalExperimentalResults, FooterView, HomeView, HeaderView},
  mounted() {
    console.log(this.$route.path)
  }
}
</script>

<template>
  <div class="layout">
    <template v-if="$route.path === '/home'">
      <div class="home-page">
        <HomeView />
      </div>
    </template>
    <template v-else-if="$route.path === '/original-experimental-results'">
      <div class="oe-page">
        <OriginalExperimentalResults />
      </div>
    </template>
    <template v-else>
      <div class="other-page">
        <header-view />
        <div class="layout_content">
          <router-view />
        </div>
        <footer-view />
      </div>
    </template>
  </div>
</template>

<style scoped lang="less">
@import "@/css/ele_change.less";
.layout {
/*  min-width: 900px;*/
/*  max-width: 1920px;
  min-width: 1280px;*/
  margin: 0 auto;
  font-family: 'Helvetica-Neue', monospace;
  overflow-y: auto;
}

.el-menu {
  background: transparent;
  text-decoration: none;
}

li.el-menu-item {
  font-size: 1rem;
  padding: 0;
  margin: 0 1.2rem;
}

.el-menu-demo .el-menu-item:hover,
.el-menu-demo .el-menu-item:hover * {
  background-color: transparent !important;
}
.submenu-title {
  font-size: 1rem;
}
.nav .el-menu-demo .el-menu-item,
.nav .el-menu-demo .submenu-title {
  color: #706592 !important;
  font-weight: bold;
  font-family: "Microsoft YaHei", serif;
}
.other-page {
  background-image: url("@/assets/pageBg.png");
  background-size: cover;
  background-position: bottom center;
 /* background-repeat: no-repeat;*/
  background-color: #eee9f5;
  padding-top: 1rem;
  font-family: 'Helvetica-Neue', monospace;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 6rem;
  min-height: 100vh;
}
.oe-page {
  background-image: url("@/assets/otherPageBg.png");
  background-size: cover;
  background-position: bottom center;
  background-color: #eee9f5;
  padding-top: 1rem;
  font-family: 'Helvetica-Neue', monospace;
  background-repeat: no-repeat;
  height: 80rem;
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 6rem;
}
@media (min-width: 1440px) {
  .oe-page, .other-page {
    background-repeat: repeat-x;
    background-size: contain;
  }
}
</style>
