<template>
  <div class="echarts">
    <div id="datasets" ref="chartContainer"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import {nextTick} from "vue";
import {datasetsData} from "@/views/home/components/chartsData";
const image = require('@/assets/chart/image.png');//作为中心背景图片

export default {
  name: 'EChartsSunburst',
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    // 组件挂载时初始化图表并添加窗口调整大小事件的监听器
    this.initChart();
    window.addEventListener('resize', this.handleResize);
    // 实现鼠标滑动交互：当鼠标滑动到下层旭日图时隐藏上层图
  },
  methods: {
    handleResize() {
      // 处理窗口调整大小事件时重新调整图表大小
      if (this.chart) {
        this.chart.resize()
        nextTick(() => {
          const fontSize = parseInt(document.documentElement.style.fontSize) / 2
          const imageSize = this.calculateImageSize(this.chart.getWidth())
          this.setOptions(this.chart, datasetsData, imageSize, fontSize)
        })
      }
    },
    calculateImageSize(radius) {
      const imageWidth = radius * 0.17; // 占据18%的半径
      return { width: imageWidth, height: imageWidth };
    },
    initChart() {
      // 初始化图表
      const chartContainer = this.$refs.chartContainer
      this.chart = echarts.init(chartContainer, null,  { renderer: 'svg' })
      const imageSize = this.calculateImageSize(this.chart.getWidth())
      const fontSize = parseInt(document.documentElement.style.fontSize) / 2
      this.setOptions(this.chart, datasetsData, imageSize, fontSize)
    },
    setOptions(chartInstance, data, imageSize, fontSize) {
      // 图表配置选项
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b}',
          backgroundColor: '#fff',
          fontSize: '0.5rem',
        },
        series: [
          {
            type: 'sunburst',
            data: data,
            radius: ['18%', '100%'],
            sort: null, // 按照data声明的顺序进行排序展示
            label: {
              rotate: 'radial',
              fontSize: `${fontSize}px`,
              color: '#282c39',
              fontFamily: 'Helvetica',
              textBorderWidth: '0.01rem',
              formatter: (params) => {
                // if(params.name.indexOf('-') !== -1) {
                //   return params.name.replace('-', '-\n')
                // }
                return params.name.replace(' ', '\n')
              }
            },
            itemStyle: {
              borderColor: "#e2d8f5",
              borderWidth: 1
            },
            labelLine: {
              length: 20,
            },
            // labelLayout: {
            //   hideOverlap: true,
            // },
            emphasis: {
              focus: 'ancestor'
            },
            animationTypeUpdate: 'rotate',
            animationDurationUpdate: 1000,
            animationEasingUpdate: 'quadraticInOut',
            levels: [
              {},
              {
                // r0: '18%',
                // r: '40%',
                // itemStyle: {
                //   borderWidth: 2
                // },
                label: {
                  rotate: 'tangential'
                }
              },
            ],
          },
        ],
        graphic: [
          {
            type: 'image',
            left: 'center',
            top: 'center',
            z: 1,
            bounding: 'all',
            style: {
              image: image,
              ...imageSize,
              opacity: 1,
            }
          },
          {
            type: 'circle',
          }
        ]
      };
      // 设置图表选项
      chartInstance.setOption(option);
      chartInstance.on('click', (params) => {
        console.log(params)
        if (params.componentType === 'graphic') {
          // 获取当前的 root 节点
          chartInstance.setOption({
            series: [{
              type: 'sunburst',
              data: data
            }]
          });
        }
      })
    }
  },
};
</script>

<style scoped>
.echarts {
  width: 29rem;
  height: 27.77rem;
/*  min-width: 618px;
  min-height: 591px;
  max-width: 700px;
  max-height: 700px;*/
  padding: 1rem;
  box-sizing: border-box;
}
#datasets {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: all 0.3s;
  cursor: pointer;
}
#datasets:hover {
  cursor: pointer;
}
</style>
