<script>
export default {
  name: "FooterView"
}
</script>

<template>
  <el-footer class="footer">
    <img src="@/assets/amor.png" alt="描述图片内容" class="logo" />
    <div style="width: 23.95rem; display: flex; justify-content: space-between;">
      <span>© 2019-2024 copyright</span>
      <span>Contact us</span>
      <span>Reference</span>
    </div>
  </el-footer>
</template>

<style scoped lang="less">
.footer {
  text-align: center;
  line-height: 1.36rem;
  display: flex;
  justify-content: space-between;
  column-gap: 10%;
  background: transparent;
  height: 3.18rem !important;
  align-items: flex-end;
  padding: 0 4.2rem 1.5rem;
  font-family: Helvetica, serif;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  span {
    font-size: 0.81rem;
    line-height: 1.09rem;
    color: #3F4760;
    font-weight: bold;
    cursor: pointer;
  }
  .logo {
    width: 2.73rem;
    height: 3.18rem;
    object-fit: contain;
  }
}
@media (min-width: 1440px) {
  .footer {
    width: 62rem;
    left: 50%;
    transform: translate(-50%);
  }
}
</style>
