<template>
  <div class="home-container">
    <div class="top-bg">
      <div class="center">
        <HeaderView />
        <div class="intro">
          <h1>Atlas of Molecular <br /> Property Prediction</h1>
          <p>{{intro.paragraphs}}</p>
        </div>
        <img src="@/assets/home/mol.png" alt="描述图片内容" class="mol-image"/>
      </div>
    </div>
    <div class="center-bottom">
      <div class="charts">
        <Representation />
        <div class="charts-description">
          <h1>Our platform offers</h1>
          <div class="list">
            <p v-for="item in intro.listItems" :key="item">
              <span class="dot"></span>
              {{ item }}
            </p>
          </div>
        </div>
      </div>
      <div class="charts">
        <div class="charts-stats">
          <div v-for="stat in stats" :key="stat.value" class="stat-item">
            <span class="number">{{ stat.value }}</span>
            <span class="label">{{ stat.label }}</span>
          </div>
        </div>
        <Datasets />
      </div>
      <div class="core-functions">
        <h1>{{ title }}</h1>
        <div class="core-function-items">
          <div v-for="(item, index) in coreFunctions" :key="item.name"
               class="core-function-item"
               @click="$router.push({name: item.route.name})"
               @mouseover="showDescription(index)"
               @mouseleave="hideDescription">
            <img class="blur-images" :src="item.backgroundImage" alt="background-image"/>
            <div class="pink-title">{{ item.name }}</div>
            <div v-if="hoveredIndex === index" class="description-popup">
              {{ descriptions[index] }}
            </div>
          </div>
        </div>
      </div>
      <footer-view />
    </div>
  </div>
</template>

<script>
import Representation from './components/Representation.vue';
import Datasets from './components/Datasets.vue';
import data from './home.js'
import router from "@/router";
import HeaderView from "@/layout/Header.vue";
import FooterView from "@/layout/Footer.vue";

export default {
  components: {
    FooterView,
    HeaderView,
    Representation,
    Datasets,
  },
  data() {
    return {
      title: 'Core Functions of AMoR',
      activeIndex: '1',
      coreFunctions: data.corefunctions,
      descriptions: data.descriptions,
      menus: data.menus,
      stats: data.stats,
      intro: data.intro,
      hoveredIndex: null,
      datasets: ["task", "数据名称", "数据量"],
      representation: ['维度']
    };
  },
  mounted() {
    this.coreFunctions = this.coreFunctions.map(item => ({
      ...item,
      backgroundImage: `${require(`@/assets/home/${item.image}`)}`
    }));
  },
  methods: {
    router() {
      return router
    },
    showDescription(index) {
      this.hoveredIndex = index;
      this.title = "Core Functions of AMoR";
    },
    hideDescription() {
      this.hoveredIndex = null;
      this.title = "Core Functions of AMoR";
    }
  }
};
</script>

<style scoped lang="less">
/*顶部包括menu和intro*/
.top-bg {
  background-image: url("@/assets/home/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
/*  aspect-ratio: 3;*/
  position: relative;
  padding-top: 1rem;
  width: 100%;
/*  min-height: 477px;*/
  box-sizing: border-box;
  .mol-image {
    position: absolute;
    right: 4.8rem;
    top: 4rem;
    width: 21.09rem;
    height: 24.45rem;
    z-index: 2;
  }
  .intro {
    padding: 2.5rem 0 2rem 6.73rem;
    //background: linear-gradient(20deg, #8967d4 0%, #b79ffd 25%, #baa2fd 50%, #d2c3fe 75%, #f6f3fe 100%);
    justify-content: start;
    flex-grow: 1; /* 占满剩余空间 */
    font-size: 1rem;
    /*background: liar-gradient(to bottom, #eeeaf6 0%, #d3c8e6 30%, #c5b6df 60%, #b8a5d7 100%);*/
    h1 {
      /*width: 28rem;*/
      letter-spacing: -0.182rem;
    }
    p {
      font-size: 0.82rem;
      width: 24.32rem;
      line-height: 1rem;
      color: #3f4760;
      margin-top: 1rem;
      font-weight: 700;
    }
  }
}
/*中间部分的charts图和文字描述*/
.center-bottom {
  padding-top: 5.27rem;
/*  min-height: 1858px;*/
  box-sizing: border-box;
  background-image: url("@/assets/background.png");
  /*background-size: 1280px 1858px;*/
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding-bottom: 6rem;
}
.charts {
  display: flex;
  padding-left: 3.86rem;
  align-items: center;
  .charts-description {
    padding-left: 1rem;
    h1 {
      font-size: 2rem;
      line-height: 2.4rem;
      margin-bottom: 0.91rem;
    }
    .list {
      text-align: left;
      padding-left: 1rem;
      font-family: Helvetica, serif;
      p {
        line-height: 1.15rem;
        font-size: 0.9rem;
        padding: 0.45rem 0;
        color: #615586;
        display: flex;
        align-items: center;
        white-space: nowrap;
        letter-spacing: 0.05rem;
      }
      .dot {
        width: 0.9rem;
        height: 0.9rem;
        border-radius: 50%;
        background-color: #7dd1c1;
        margin-right: 1rem;
        display: inline-block;
      }
    }
  }
  .charts-stats {
    margin-left: 6.7rem;
    .stat-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 13.6rem;
      height: 4.18rem;
      text-align: center;
      background: linear-gradient(0deg, rgba(170, 149, 209, 0.25) -66.11%, rgba(229, 223, 241, 0.423544) 16.74%, rgba(255, 255, 255, 0.5) 73.33%);
      //background: linear-gradient(to bottom, #eae5f3 0%, #e6e1f1 35%, #e4ddf0 50%, #dcd4ec 90%, #d5cbe9 100%);
      box-shadow: 0.14rem 0.23rem 0.23rem 0 #8784a3; // 3 5
      min-width: 11rem;
      margin-bottom: 1rem;
      margin-right: 2rem;
      border: 0.05rem solid #bdb6d1; // 1
      border-top-color: #d5cbe8;
      border-left-color: #d5cbe8;
      border-radius: 0.182rem;
      .label, .number {
        color: #615586;
        font-weight: bold;
      }
      .number {
        font-size: 1.82rem;
        display: inline-block;
        line-height: 2.23rem;
      }
      .label {
        font-size: 0.82rem;
        line-height: 1rem;
      }
    }
  }
}
@media (min-width: 1281px) {
  .charts {
    padding-right: 2.86rem;
    justify-content: space-around;
  }
}
h1 {
  font-size: 3.27rem;
  font-weight: 700;
  color: #3f4760;
  line-height: 4rem;
}

.center-charts {
  height: 40rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between; /* 根据需要居中内容 */
  overflow: hidden; /* 确保背景不会溢出容器 */
  padding: 2rem 5% 0;
  column-gap: 1rem;
  .charts {
    flex: 1;
    display: flex;
    width: 50%;
  }
}
.core-functions {
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0 3.8rem 1rem;
  text-align: center;
  h1 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2.5rem;
    margin-top: 1rem;
  }
}
.core-function-items {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 1281px) {
  .core-function-items {
    justify-content: space-around;
  }
}
.core-function-item {
  width: 11.5rem;
  height: 8.23rem;
  color: #615586;
  font-size: 0.82rem;
  line-height: 1rem;
  font-weight: 700;
  padding: 0.7rem 0.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: -0.091rem 0.182rem 0.182rem #7b769c;
  border-radius: 0.182rem;
  background: linear-gradient(0deg, rgba(170, 149, 209, 0.5) -66.11%, rgba(229, 223, 241, 0.847088) 16.74%, #FFFFFF 73.33%);
  position: relative;
}
.blur-images {
  height: 4rem;
  object-fit: contain;
  /*mask-image: linear-gradient(to right, transparent 0%, rgba(229, 222, 244, 0.7) 2%, #e5def4 3%, #e5def4 96%, rgba(229, 222, 244, 0.7) 98%, transparent 100%);*/
}
.pink-title {
  font-size: 0.82rem;
  line-height: 1rem;
}
.description-popup {
  position: absolute;
  top: 0; /* 对齐到 `.core-function-item` 的顶部 */
  left: 0; /* 对齐到 `.core-function-item` 的左边 */
  background: linear-gradient(180deg, rgba(170, 149, 209, 0.45) 0%, rgba(220, 211, 236, 0.712922) 5%, rgba(255, 255, 255, 0.9) 100%);
  border-radius: 4px;
  color: #3f4760; /* 改为白色以提高对比度 */
  z-index: 10;
  width: 100%; /* 使其宽度与 `.core-function-item` 相同 */
  height: 100%;
  box-sizing: border-box;
  opacity: 0; /* 默认隐藏 */
  visibility: hidden; /* 默认隐藏 */
  transition: opacity 0.3s ease, visibility 0.3s ease; /* 添加 visibility 过渡效果 */
  padding: 0.5rem;
  line-height: 1.1rem;
  font-size: 0.818rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  font-family: Helvetica, sans-serif;
}
.core-function-item:hover .description-popup {
  opacity: 1; /* 悬浮时显示 */
  visibility: visible; /* 悬浮时显示 */
}
@media (min-width: 1440px) {
  .center {
    width: 61rem;
    margin: 0 auto;
    position: relative;
    .intro {
      padding-left: 4.73rem;
    }
  }
  .center-bottom {
    background-size: contain;
    background-repeat: repeat-x;
  }
  .top-bg .mol-image {
    transform: translate(30%);
    right: 8.8rem;
  }
  .top-bg {
    background-position: center bottom;
  }
  .charts {
    justify-content: center;
    column-gap: 6rem;
  }
  .core-functions {
    width: 62rem;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 1.8rem 1rem;
  }
}
</style>

