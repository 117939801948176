const corefunctions= [
    { name: 'Molecular Property Prediction', image: 'function-1.png', route: { name: 'PropertyPrediction' } },
    { name: 'Molecular Rrepresentation Generation', image: 'function-2.png', route: { name: 'RepresentationGeneration' } },
    { name: 'Molecular Representation Evaluation', image: 'function-3.png', route: { name: 'ComprehensiveResults' } },
    { name: 'Datasets and Codes Download', image: 'function-4.png', route: { name: 'Download' }  }
  ]
const descriptions=[
    'Property prediction for physiological, biophysical, physicochemical, and quantum mechanical tasks using 31 different representation methods.',
    'Comprehensive generation and description of 12 fixed and 19 embedded representations.',
    'Comparative performance analysis of 31 representation methods across 17 benchmark datasets.',
    'Access to baseline datasets and model codes for download.'
  ]
const menus= [
    { index: '1', title: 'Home', route: { name: 'Home' } },
    { index: '2', title: 'Property Prediction', route: { name: 'PropertyPrediction' } },
    { index: '3', title: 'Representation Generation', route: { name: 'RepresentationGeneration' } },
    {
      index: '4',
      title: 'Benchmark',
      submenu: [
        { index: '4-1', title: 'Comprehensive Results Display', route: { name: 'ComprehensiveResults' } },
        { index: '4-2', title: 'Original Experimental Results Display', route: { name: 'OriginalExperimentalResults' } }
      ]
    },
    { index: '5', title: 'Download', route: { name: 'Download' } },
    { index: '6', title: 'Help', route: { name: 'Help' } }
  ]
  const stats = [
    { value: 29, label: 'Representation Method' },
    { value: 705, label: 'Property Prediction Task' },
    { value: 17, label: 'Datasets' },
    { value: 319803, label: 'Compounds' }
  ]
  const intro = {
    paragraph: [
      'Atlas of Molecular Property Prediction and Representation Benchmark (AMoR) website Dedicated to the comprehensive',
      'Dedicated to the comprehensive evaluation of molecular representations for small molecule compounds. Our platform offers:'
    ],
    paragraphs: 'and Representation Benchmark (AMoR) website Dedicated to the comprehensive. Dedicated to the comprehensive evaluation of molecular representations for small molecule compounds.',
    listItems: [
      'Tools for Property Prediction',
      'Generation of Molecular Representations',
      'Displaying Benchmark',
      'Downloadable Resources'
    ]
  };
export default {
    corefunctions,
    descriptions,
    menus,
    stats,
    intro
}
