<template>
  <div class="echarts">
    <div id="representation" ref="chartContainer"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import {representationData} from "@/views/home/components/chartsData";
import {nextTick} from "vue";
const image = require('@/assets/chart/image.png'); // 作为圆圈中心背景

export default {
  name: 'EChartsSunburst',
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    // 组件挂载时初始化图表并添加窗口调整大小事件的监听器
    this.initChart();
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      // 处理窗口调整大小事件时重新调整图表大小
      if (this.chart) {
        this.chart.resize()
        const fontSize = parseInt(document.documentElement.style.fontSize) / 2
        nextTick(() => {
          const imageSize = this.calculateImageSize(this.chart.getWidth())
          this.setOptions(this.chart, representationData, imageSize, fontSize)
        })
      }
    },
    calculateImageSize(radius) {
      const imageWidth = radius * 0.17; // 占据18%的半径
      return { width: imageWidth, height: imageWidth };
    },
    initChart() {
      // 初始化图表
      const chartContainer = this.$refs.chartContainer
      this.chart = echarts.init(chartContainer, null,  { renderer: 'svg' })
      const imageSize = this.calculateImageSize(this.chart.getWidth())
      const fontSize = parseInt(document.documentElement.style.fontSize) / 2
      this.setOptions(this.chart, representationData, imageSize, fontSize)
    },
    setOptions(chartInstance, data, imageSize, fontSize) {
      // 图表配置选项
      const option = {
        tooltip: {
          trigger: 'item',
          backgroundColor: '#fff',
          fontSize: '0.5rem',
          formatter: (params) => {
            if (params.name.indexOf('\n')) {
              return params.name.replaceAll('\n', '')
            } else {
              return params.name
            }
          }
        },
        series: [
          {
            type: 'sunburst',
            clockwise: true, // 顺时针排布
            startAngle: 90,
            data: data,
            radius: ['18%', '100%'],
            sort: null,
            label: {
              rotate: 'radial',
              // rotate: 'tangential',
              fontSize:  `${fontSize}px`,
              color: '#282c39',
              fontFamily: 'Helvetica',
              textBorderWidth: '0.01rem',
              // fontWeight: 'bold',
              formatter: (params) => {
                if(params.name.indexOf('-') !== -1 && !params.name.startsWith('N')) {
                  return params.name.replaceAll('-', '-\n')
                }
                return params.name.replace(' ', '\n')
              }
            },
            labelLine: {
              length: 20,
            },
            itemStyle: {
              borderColor: "#e2d8f5",
              borderWidth: 1
            },
            labelLayout: {
              // hideOverlap: true,
              moveOverlap: true,
            },
            emphasis: {
              focus: 'ancestor'
            },
            animationTypeUpdate: 'rotate',
            animationDurationUpdate: 1000,
            animationEasingUpdate: 'quadraticInOut',
            levels: [
              {
                // r0: '0%',
                // r: '100%',
                // itemStyle: {
                //   color: '#91cc75',
                //   // opacity: 0
                // }
              },
              {
                label: {
                  rotate: 'tangential'
                }
              },
              {
                // r0: '45%',
                // r: '78%',
                label: {
                  rotate: 'tangential'
                }
              },
              {
                // r0: '78%'
              }
            ],
          },
        ],
        graphic: [
          {
            type: 'image',
            left: 'center',
            top: 'center',
            z: 1,
            bounding: 'all',
            style: {
              image: image,
              ...imageSize,
              opacity: 1,
            }
          },
          {
            type: 'circle',
          }
        ]
      }
      // 设置图表选项
      chartInstance.setOption(option)
      chartInstance.on('click', (params) => {
        // 点击中间图片区域图形时，进行数据上钻回退操作
        if (params.componentType === 'graphic') {
          // 获取当前的 root 节点
          chartInstance.setOption({
            series: [{
              type: 'sunburst',
              data: representationData
            }]
          })
        }

        // 点击的是第一层的节点
        const firstLevelNames = ["1D Representations", '2D Representations', '3D Representations', 'Hybrid Representations']
        if (params.componentType === 'series' && firstLevelNames.includes(params.name)) {
          // option.series[0].levels[0].itemStyle.color = params.color
          // chartInstance.setOption({
          //   series: [{
          //     type: 'sunburst',
          //     data: [params.data]
          //   }]
          // })
        }
      })
    },
  }
};
</script>

<style scoped>
.echarts {
  width: 29rem;
  height: 27.77rem;
/*  min-width: 618px;
  min-height: 591px;
  max-width: 700px;
  max-height: 700px;*/
  padding: 1rem;
  box-sizing: border-box;
}
#representation {
  width: 100%;
  height: 100%;
  z-index: 2;
  border-radius: 50%;
  cursor: pointer;
}
#representation:hover {
  cursor: pointer;
}
</style>
